import {
  GET_ALL,
  SIDE_BAR_SHOW,
  SET_USER,
  SIGN_OUT,
  USERS_ERROR,
  EMPLOYEE_CHANGE,
  MONTH_CHANGE,
  GLOBAL,
  INTERNAL,
  RETAINER,
  PROJECT,
  MONTH_SHORT_ARR,
  BILLABLEDAYS,
  convertMonthToObj,
  GET_EMPLOYEES,
} from '../types'

const initialState = {
  allData: {
    employees: [],
    agencies: {},
  },
  user: {
    email:"abc@abc.com"
  },
  isAdmin: false,
  selectedEmployee: '',
  selectedMonth: '',
  sidebarShow: false,
  loading: true,
  ready: true,
  highlight: {
    totalUtilization: 0,
    billableUtilization: 0,
    internalUtilization: 0,
    globalUtilization: 0,
    totalDays: 0,
    billableDays: 0,
    internalDays: 0,
    globalDays: 0,
  },
  monthlyClientAllocation: {},
  monthlyRetainerVsProject: {},
  clientRetainerVsProject: {},
  retainerTot: 0,
  projectTot: 0,
  jobNumbers: {},
  employeeAllocation: [],
}

export default function (state = initialState, action) {
  let dashboardValues
  let employeeAllocation

  switch (action.type) {
    case SIGN_OUT:
      return {
        ...state,
        user: {},
        ready: false,
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        ready: true,
      }
    case GET_EMPLOYEES:
      const employees = action.payload.onlyActive
      const selectedEmployee = employees.filter((item) => {
        return item.value == state.user.email
      })
      return {
        ...state,
        selectedEmployee: selectedEmployee[0],
        employees,
      }
    case GET_ALL:
      /*let keys = Object.keys(action.payload)
      let arr = []
      keys.forEach((code) => {
        if (action.payload[code] == state.user.email) {
          sEmp = action.payload.employees[code]
          if (sEmp.all == 'yes') {
            isAdmin = true
          }
        }
      })*/

      return {
        ...state,
        allData: action.payload,
        //selectedMonth: action.payload.monthArr[0],
        loading: false,
      }
    case EMPLOYEE_CHANGE:
      //console.log(action.payload, state.selectedMonth)
      //dashboardValues = updateDashboard(state.allData, action.payload, state.selectedMonth)
      return {
        ...state,
        selectedEmployee: action.payload,
        ...dashboardValues,
      }
    case MONTH_CHANGE:
      employeeAllocation = updateEmplyeeAllocation(state.allData, action.payload)
      dashboardValues = updateDashboard(state.allData, state.selectedEmployee, action.payload)
      return {
        ...state,
        selectedMonth: action.payload,
        ...dashboardValues,
        employeeAllocation,
      }
    case SIDE_BAR_SHOW:
      return {
        ...state,
        sidebarShow: action.payload,
      }
    default:
      return state
  }
}

const updateDashboard = (allData, selectedEmployee, selectedMonth) => {
  const parsedData = parseDashboardData(allData, selectedEmployee, selectedMonth)
  //console.log(parsedData)
  //
  let dashboardObj = {
    highlight: updateHightlight(allData, selectedEmployee, selectedMonth),
    ...parsedData,
  }
  return dashboardObj
}

const updateHightlight = (allData, selectedEmployee, selectedMonth) => {
  let highlight = {
    totalUtilization: 0,
    billableUtilization: 0,
    internalUtilization: 0,
    globalUtilization: 0,
    totalDays: 0,
    billableDays: 0,
    internalDays: 0,
    globalDays: 0,
  }
  //
  const selectedMonthObj = convertMonthToObj(selectedMonth)
  //
  allData.allocation.forEach((category) => {
    category.items.forEach((entry) => {
      const days = parseFloat(entry[selectedEmployee.code])
      const fromObj = convertMonthToObj(entry.From)
      const toObj = convertMonthToObj(entry.To)
      //
      const isYearInRange =
        selectedMonthObj.year >= fromObj.year && selectedMonthObj.year <= toObj.year
      const isMonthInRange =
        selectedMonthObj.month >= fromObj.month && selectedMonthObj.month <= toObj.month
      if (!isNaN(days) && isYearInRange && isMonthInRange) {
        switch (category.type) {
          case GLOBAL:
            highlight.globalDays += days
            break
          case INTERNAL:
            highlight.internalDays += days
            break
          case RETAINER:
          case PROJECT:
            highlight.billableDays += days
            break
        }
      }
    })
  })
  highlight.totalDays = highlight.globalDays + highlight.internalDays + highlight.billableDays
  highlight.totalUtilization = Math.round((highlight.totalDays / BILLABLEDAYS) * 100)
  if (highlight.totalDays > 0) {
    highlight.billableUtilization = Math.round((highlight.billableDays / BILLABLEDAYS) * 100)
    highlight.internalUtilization = Math.round((highlight.internalDays / BILLABLEDAYS) * 100)
    highlight.globalUtilization = Math.round((highlight.globalDays / BILLABLEDAYS) * 100)
  }
  return highlight
}

const parseDashboardData = (allData, selectedEmployee, selectedMonth) => {
  const selectedMonthObj = convertMonthToObj(selectedMonth)
  let clientRetainerVsProject = {
    labels: [],
    datasets: [
      {
        label: 'Retainer',
        backgroundColor: '#f87979',
        data: [],
      },
      {
        label: 'Projects',
        backgroundColor: '#f62980',
        data: [],
      },
    ],
  }
  //
  let monthlyClientAllocation = {
    labels: [],
    datasets: [],
  }
  //
  let monthlyRetainerVsProject = {
    labels: ['Retainer', 'Projects'],
    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    datasets: [],
  }
  // Project vs Retainer pie chart
  let retainerTot = 0
  let projectTot = 0
  // Bar chart
  let barClientList = []
  let retainerArr = []
  let projectsArr = []
  // Time allocation pie chart
  let clientList = []
  let clientData = []
  let bgColor = []
  //job number array
  let jobNumbers = {
    columns: [
      {
        key: 'jobNumber',
        label: 'Job Number',
        _props: { scope: 'col' },
      },
      {
        key: 'type',
        label: 'Type',
        _props: { scope: 'col' },
      },
      {
        key: 'client',
        label: 'Client Name',
        _props: { scope: 'col' },
      },
      ,
      {
        key: 'desc',
        label: 'Description',
        _props: { scope: 'col' },
      },
    ],
    items: [],
  }
  //
  allData.allocation.forEach((category) => {
    category.items.forEach((entry) => {
      const days = parseFloat(entry[selectedEmployee.code])
      const fromObj = convertMonthToObj(entry.From)
      const toObj = convertMonthToObj(entry.To)
      const isYearInRange =
        selectedMonthObj.year >= fromObj.year && selectedMonthObj.year <= toObj.year
      const isMonthInRange =
        selectedMonthObj.month >= fromObj.month && selectedMonthObj.month <= toObj.month
      //job numbers
      let clnt = {}
      allData.clients.forEach((item) => {
        if (item.code == entry.Code) {
          clnt = item
          return
        }
      })
      jobNumbers.items.push({
        jobNumber: entry.Job_Number,
        client: clnt.label,
        type: entry.Type,
        desc: entry.Project_Description,
      })
      //
      if (!isNaN(days) && isYearInRange && isMonthInRange) {
        const client = getClientData(allData.clients, entry.Code)
        const inx = clientList.indexOf(client.label)
        //calcuale retainer vs project
        if (entry.Type == RETAINER) {
          retainerTot += days
        } else if (entry.Type == PROJECT) {
          projectTot += days
        }
        //
        if (inx == -1) {
          clientList.push(client.label)
          bgColor.push(client.color)
          clientData.push(days)
        } else {
          clientData[inx] += days
          //
        }
        //
        const barcharInx = barClientList.indexOf(client.label)
        const validType = entry.Type == RETAINER || entry.Type == PROJECT

        if (barcharInx == -1 && validType) {
          barClientList.push(client.label)
          if (entry.Type == RETAINER) {
            retainerArr.push(days)
            projectsArr.push(0)
          } else if (entry.Type == PROJECT) {
            retainerArr.push(0)
            projectsArr.push(days)
          }
        } else if (validType) {
          if (entry.Type == RETAINER) {
            retainerArr[barcharInx] += days
          } else if (entry.Type == PROJECT) {
            projectsArr[barcharInx] += days
          }
        }
      }
    })
  })
  //console.log(clientList, bgColor, clientData)
  //
  monthlyClientAllocation.labels = clientList
  monthlyClientAllocation.datasets.push({
    backgroundColor: bgColor,
    data: clientData,
  })
  //
  monthlyRetainerVsProject.datasets.push({
    backgroundColor: bgColor,
    data: [retainerTot, projectTot],
  })
  //
  clientRetainerVsProject.datasets[0].data = retainerArr
  clientRetainerVsProject.datasets[1].data = projectsArr
  clientRetainerVsProject.labels = barClientList
  //
  //console.log(jobNumbers)
  //
  return {
    monthlyClientAllocation,
    monthlyRetainerVsProject,
    clientRetainerVsProject,
    jobNumbers,
    retainerTot,
    projectTot,
  }
}

const updateEmplyeeAllocation = (allData, selectedMonth) => {
  const employeeAllocation = []
  allData.employees.forEach((employee) => {
    let employeeBookedEntries = []
    const selectedMonthObj = convertMonthToObj(selectedMonth)
    allData.allocation.forEach((category) => {
      var list = category.items.filter((entry) => {
        const fromObj = convertMonthToObj(entry.From)
        const toObj = convertMonthToObj(entry.To)
        const isYinRnge =
          selectedMonthObj.year >= fromObj.year && selectedMonthObj.year <= toObj.year
        const isMthRnge =
          selectedMonthObj.month >= fromObj.month && selectedMonthObj.month <= toObj.month

        //console.log(isYinRnge && isMthRnge && entry[employee.code] != '')
        if (isYinRnge && isMthRnge && !isNaN(entry[employee.code])) {
          return true
        }
      })
      const cleanedList = list.map((entry) => {
        return {
          Type: entry.Type,
          Code: entry.Code,
          Project_Description: entry.Project_Description,
          From: entry.From,
          To: entry.To,
          Job_Number: entry.Job_Number,
          Show_In_Menu: entry.Show_In_Menu,
          days: parseFloat(entry[employee.code]),
        }
      })
      employeeBookedEntries = [...employeeBookedEntries, ...cleanedList]
    })
    const department = allData.departments.filter((i) => i.code == employee.department)
    employee.dpartmentSlug = department[0].slug
    employee.items = employeeBookedEntries
    employeeAllocation.push(employee)
  })

  return employeeAllocation
}

const getClientData = (fromList, forCode) => {
  let client = {}
  //console.log(fromList, forCode)
  //
  fromList.forEach((c) => {
    if (c.code == forCode) {
      client = c
      return
    }
  })
  return client
}
